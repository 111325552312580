<template>
  <div>
    
    <b-button v-b-toggle.collapse-1 variant="primary">Filtros avançados</b-button>    
    <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <b-row>
        <b-col cols="2">
            <b-form-group label="Nome" label-for="Nome">
              <b-form-input v-model="filters.nameFilter"/>
            </b-form-group>
        </b-col>
          <b-col cols="2">
              <b-form-group label="Tipo" label-for="status-cota">
                  <b-form-select
                      id="status-cota"
                      v-model="filters.tipoFilter"
                      :options="listTipo"/>
              </b-form-group>
          </b-col> 
        <b-col cols="2">
            <b-form-group label="Telefone" label-for="Telefone">
              <b-form-input v-model="filters.phoneNumberFilter" v-mask="['(##) #####-####']"/>
            </b-form-group>
        </b-col>     
        <b-col cols="2">
            <b-form-group label="Status" label-for="status-cota">
                <b-form-select
                    id="status-cota"
                    v-model="filters.statusFilter"
                    :options="listStatus"/>
            </b-form-group>
        </b-col> 
        <b-col cols="2">
            <b-form-group label="Status Documento" label-for="status-cota">
                <b-form-select
                    id="status-cota"
                    v-model="filters.statusDocumentFilter"
                    :options="listStatusDocumento"/>
            </b-form-group>
        </b-col> 
        </b-row>
        <b-row>
          <div class="aling-buttons button-left">
            <b-button variant="primary" @click="clear()">Limpar</b-button>  
          </div>
          <div class="aling-buttons">
            <b-button variant="primary" @click="getList()">Pesquisar</b-button>  
          </div>
        </b-row>
      </b-card>
    </b-collapse>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Procurar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Procurar"
            type="text"
            class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      id="table_investors"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span>{{ props.row.name }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'isActive'">
          <b-badge :variant="statusVariant(props.row.isActive)">
            {{ props.row.isActive }}
          </b-badge>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'documentInvestorSituation'">
          <b-badge v-if="props.row.documentInvestorSituation" :variant="documentStatusVariant(props.row.documentInvestorSituation)">
            {{ documentStatusVariantName(props.row.documentInvestorSituation) }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link
              v-b-tooltip.hover.v-secondary
              title="Editar conta"
              :to="{
              name: 'editing-user-data',
              params: { usuario: props.row, isInvestor: true },
            }">
            <feather-icon icon="Edit2Icon" class="mr-50" />
          </b-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              v-model="currentPage"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BAvatar, BInputGroupText, BInputGroupAppend, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BIcon, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'
import Loader from '@/components/Loader.vue'

import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeBasic } from '../codeTable'
// import ModalUser from './ModalUser.vue'

export default {
  components: {
    BInputGroupText, 
    BInputGroupAppend,
    BInputGroup,
    BPagination,
    BFormSelect,
    BCard,
    BCollapse,
    BButton,
    BLink,
    BBadge,
    VueGoodTable,
    BIcon,
    BFormGroup, 
    BRow, 
    BCol,
    BFormInput,
    BAvatar,
    Loader
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-toggle': VBToggle
  },
  data() {
    return {
      pageLength: 10,
      currentPage: 1,
      dir: false,
      filters:{},
      codeBasic,
      columns: [
        {
          label: 'Nome completo / Razão social',
          field: 'name',
        },
        {
          label: 'Telefone',
          field: 'phoneNumber',
        },
        {
          label: 'Funções',
          field: 'roles',
        },
        {
          label: 'Status',
          field: 'isActive',
        },
        {
          label: 'Documento',
          field: 'documentInvestorSituation',
        },
        {
          label: 'Ação',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [
        {
          1: 'Inativo',
          2: 'Ativo',
          3: 'Rejeitado',
          4: 'Bloqueado',
          5: 'Suspenso',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],

      documentStatus: [
        {
          1: 'Aprovado',
          2: 'Pendente Validação',
          4: 'Rejeitado',
        },
        {
          1: 'light-success',
          2: 'light-danger',
          4: 'light-warning'
        },
      ],
      listTipo: [
        {
            text: 'Corretor',
            value: 'BROKER',
        },
        {
            text: 'Investidor',
            value: 'INVESTOR',
        },
      ],
      listStatus: [
        {
            text: 'Ativo',
            value: true,
        },
        {
            text: 'Inativo',
            value: false,
        },
      ],
      listStatusDocumento: [
        {
            text: 'Aprovado',
            value: 1,
        },
        {
            text: 'Pendente Validação',
            value: 2,
        },
        {
            text: 'Reprovado',
            value: 4,
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Inativo: 'light-danger',
        Ativo: 'light-success',
        Rejeitado: 'light-danger',
        Bloqueado: 'light-warning',
        Suspenso: 'light-info',
      }

      return status => statusColor[status]
    },

    documentStatusVariant() {
      const statusColor = {
        1: 'light-success',
        2: 'light-warning',
        4: 'light-danger',
      }

      return documentStatus => statusColor[documentStatus]
    },
    documentStatusVariantName() {
      const statusName = {
        1: 'Aprovado',
        2: 'Pendente Validação',
        4: 'Rejeitado',
      }
      return status => statusName[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  async created() {
    await this.getList()
  },

  methods:{
   async getList(){
    this.loading = true
    let parans = this.getFilters()
    const responseUser = await this.$http.post(
      '/api/services/app/User/GetUsersInvestors',
      parans,
    )
    this.row = responseUser.data.result.items
    const dataResUser = this.row

    for (let i = 0; i < dataResUser.length; i++) {
      const rolesName = dataResUser[i].roles
      let txtRolesName = ''
      for (const x in rolesName) {
        txtRolesName += `${rolesName[x].roleName} `
      }

      dataResUser[i].isActive = dataResUser[i].isActive === true ? 'Ativo' : 'Inativo'
      dataResUser[i].roles = txtRolesName.trim().replace(' ', ' - ')
    }

    this.rows = dataResUser
   },

   getFilters(){
      let filters = {
        maxResultCount: 1000,
        role: 'INVESTOR',
      }

      if(this.filters.tipoFilter !== undefined) {
        filters.role = this.filters.tipoFilter
      }
      if(this.filters.statusFilter !== undefined) {
        filters.isActive = this.filters.statusFilter
      }
      if(this.filters.statusDocumentFilter !== undefined) {
        filters.statusDocumentFilter = this.filters.statusDocumentFilter
      }
      if(this.filters.nameFilter !== undefined) {
        filters.nameFilter = this.filters.nameFilter
      }
      if(this.filters.phoneNumberFilter !== undefined) {
        filters.phoneNumberFilter = this.filters.phoneNumberFilter
      }
      return filters
    },

    clear(){
      this.filters = {}
      this.getList()
    },
  }

}
</script>
<style>
body {
    background-color: #f8f8f8 !important;
}
 #table_investors tr {
  border: 0;
  display: block;
  margin: 15px 8px;
  border: 1px #fff solid;
  border-radius: 10px;
}

 #table_investors td {
  padding: 5px;
}
 #table_investors tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

 #table_investors  .vgt-table.bordered td,  #table_investors .vgt-table.bordered th {
  border: #fff !important;
}

 #table_investors table.vgt-table td {
   padding: 0.75em 0.75em 0.75em 0.75em !important;
 }

 #table_investors .vgt-left-align {
   text-align: left !important;
 }

 #table_investors table.vgt-table td {
   vertical-align: baseline !important;
   color: #6e6b7b;
 }
 #table_investors table.vgt-table thead {
   display: none;
 }
 #table_investors table.vgt-table tbody {
   font-size: 13px;
 }
 #table_investors table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
 }
 #table_investors table tbody td { max-width: 285px; min-width: 285px; }
 #table_investors table tbody td:first-child{ max-width: 300px; min-width: 300px; }
 #table_investors table tbody td:nth-child(2) { max-width: 150px; min-width: 150px; }
 #table_investors table tbody td:nth-child(3) { max-width: 280px; min-width: 280px; }
 #table_investors table tbody td:nth-child(4) { max-width: 150px; min-width: 150px; }
 #table_investors table tbody td:nth-child(5) { max-width: 200px; min-width: 200px; }
 #table_investors table tbody td:nth-child(6) { max-width: 80px; min-width: 80px; float: right; margin-top: -50px; }
 #table_investors a { color: #828282; }
 #table_investors a:hover { color: #F8AC18; }

 #table_investors td[colspan="6"] {
   text-align: center;
   display: inline;
   white-space: nowrap;
   overflow: hidden;
   width: 100%;
 }
.aling-buttons{
  margin-top: 1.6%;
}
.button-left{
  margin-left: 1%!important;
  margin-right: 1% !important;
}
</style>
